'use strict';

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} icon - icon that was clicked to add a product to the wishlist
 * @param {Object} imageContainer <div> - image container div to append the data once the product is wishlisted
 */

function displayMessageAndChangeIcon(data, icon, imageContainer) {
  $.spinner().stop();
  var customerDetails = $('.customer-details').data('customer');
  var customerStatus = customerDetails && customerDetails.firstName;

  if (data.success) {
    if (!customerStatus) {
      if (imageContainer.find('.customer-content').hasClass('d-none')) {
        if (typeof Storage !== 'undefined') {
          imageContainer.find('.wishlistTile span').hide();
          if (!sessionStorage.wishListClicked) {
            sessionStorage.wishListClicked = 1;
            imageContainer
              .addClass('wishlist_checked')
              .find('.customer-content')
              .removeClass('d-none');
          } else {
            imageContainer
              .removeClass('wishlist_checked')
              .find('.wishlistTile span')
              .show();
          }
        }
      }
    }
  }
}

function addToWishlist() {
  $('body').on('click', '.wishlistTile.select-wishlist', function (e) {
    e.preventDefault();
    e.stopPropagation();

    var $this = $(this);
    var icon = $this.find($('i'));
    var url = $this.attr('href');
    var pid = $this.closest('.product').data('pid');
    var $productDetail = $this.closest('.product-detail');
    var optionId = $productDetail.find('.product-option').attr('data-option-id') || null;
    var optionVal = $productDetail.find('.options-select option:selected').attr('data-value-id') || null;
    var $imageContainer = $this.closest('.product-tile').find('.image-container');

    if (!url || !pid) {
      return;
    }

    $.spinner().start();
    $.ajax({
      url: url,
      type: 'post',
      dataType: 'json',
      data: {
        pid: pid,
        optionId: optionId,
        optionVal: optionVal
      },
      success: function (data) {
        if (!data.product) {
          $.spinner().stop();
          return;
        }
        var customerDetails = $('.customer-details').data('customer');
        var customerStatus = customerDetails && customerDetails.firstName;

        if (!customerStatus) {
          $('.wishlistTile span').removeAttr('style');
          $('.bagde-img').removeAttr('style');
          $('.tile-image').removeClass('guest-user');
        }

        if ($($this).closest('.criteo-sponsored-product').length) {
          data.product.ad_type = 'sponsored product';
          data.product.ad_position = 'in-grid';
        } else if (location.search.indexOf('retail_network') > -1) {
          var url = new URL(document.location);
          var [pageType, adType, adPosition] = url.searchParams.get('retail_network') ? url.searchParams.get('retail_network').split('^') : '';
          data.product.ad_page_type = pageType || '';
          data.product.ad_type = adType || '';
          data.product.ad_position = adPosition || '';
        }
        $('body').trigger('adobeTagManager:addToFav', data.product);

        $('.customer-content').addClass('d-none');
        if (!customerStatus) {
          $('.wishlistTile span').removeAttr('style');
        }

        var productObj = data.product;
        var $productTile = $('.product-tile');
        var $productTileWrap = $productTile.closest('div[data-pid="' + productObj.id + '"]');

        if (!$productTileWrap.length) {
          $productTileWrap = $productTile.closest('div[data-pid="' + productObj.masterProductID + '"]');
        }

        var $addToCartBtn = $productTileWrap.find('.ajax-update-atc-button');
        $addToCartBtn.addClass('wishlist-add-to-bag');

        if (
          productObj.productObjType === 'standard' ||
          productObj.hasOnlyColor ||
          productObj.hasOneSize ||
          productObj.hasOnlyOneVariant ||
          (productObj.readyToOrder && productObj.available)
        ) {
          $addToCartBtn.addClass('add-to-cart');
        } else if (!$('body').is('.search-page')) {
          $addToCartBtn.addClass('item-edit');
        }

        $imageContainer = $productTileWrap.find('.image-container');
        displayMessageAndChangeIcon(data, icon, $imageContainer);

        var $pgName = $('.pg-name');
        if ($pgName.length && $pgName.val() === 'wishlist') {
          window.location.reload();
        }
      },
      error: function (err) {
        displayMessageAndChangeIcon(err, icon, $imageContainer);
      },
      complete: function (data) {
        var customerDetails = $('.customer-details').data('customer');
        var customerStatus = customerDetails && customerDetails.firstName;
        var writeOnlyArray = false;
        var writeOnlyCarousel = false;
        var $gridHeader = $('.grid-header');

        if ($gridHeader.length) {
          writeOnlyArray = $gridHeader.data('write-only-array');
        } else {
          writeOnlyCarousel = $('#maincontent').data('write-only-carousel');
        }

        if (data.status === 200 && data.responseText) {
          var productObj = JSON.parse(data.responseText).product;
          var alreadyAdded = JSON.parse(data.responseText).added;
          var $productTile = $('.product-tile');

          if (!productObj) {
            if (alreadyAdded && (writeOnlyArray || writeOnlyCarousel)) {
              var $writeOnlyTile = $productTile.closest('div[data-pid="' + JSON.parse(data.responseText).pid + '"]');

              $writeOnlyTile
                .find('.wishlistTile')
                .removeClass('select-wishlist')
                .addClass('deselect-wishlist js-cart-wishlist-tile')
                .find('span')
                .addClass('svg-svg-96-heart-gray svg-svg-96-heart-gray-dims');
            }
            if (!customerStatus) {
              $.spinner().stop();
            }
            return;
          }

          var $productTileWrapper = $productTile.closest('div[data-pid="' + productObj.id + '"]');

          if (!$productTileWrapper.length) {
            $productTileWrapper = $productTile.closest('div[data-pid="' + productObj.masterProductID + '"]');
          }

          var $wishlistTile = $productTileWrapper.find('.wishlistTile');

          if (!customerStatus) {
            $wishlistTile.closest('.image-container').find('.tile-image').addClass('guest-user');
            $wishlistTile.find('.customer-content').addClass('d-none');
          }

          $wishlistTile
            .removeClass('select-wishlist')
            .addClass('deselect-wishlist')
            .find('span')
            .addClass('svg-svg-96-heart-gray svg-svg-96-heart-gray-dims');

          if ($('body').is('.cart-page')) {
            $wishlistTile.addClass('js-cart-wishlist-tile');
          }
        }
      }
    });
  });
}

(function () {
  var elWishlist = $('.wishlist-page').length;
  var $wishlistItemCards = $('.wishlistItemCards');

  // By default changing the heart icon Selected in wishlist page.
  if (elWishlist > 0) {
    $wishlistItemCards.find('.product-tile .image-container').addClass('wishlist_checked');
    $wishlistItemCards.find('.wishlistTile').removeClass('select-wishlist').addClass('deselect-wishlist');
    $wishlistItemCards.find('.wishlistTile span').addClass('svg-svg-96-heart-gray svg-svg-96-heart-gray-dims')

    var $wishlistbtn = $wishlistItemCards.find('button.prdt_tile_btn');
    $wishlistbtn.each((index, element) => {
      var $element = $(element);
      var productType = $element.data('producttype');
      if (productType === 'master') {
        $element.addClass('add-to-cart-global');
      } else {
        $element.addClass('wishlist-tile-atc');
      }
    });
  }

  $('body').on('click', '.account-merge .close', function () {
    $('.account-merge').addClass('d-none');
  });

  $(document).ready(function () {
    $('.TTteaser__rating').each(function () {
      var currentdata = $(this).parents('.product').find('.product-tile .hover-bg-content .tile-body-footer');
      $(this).appendTo(currentdata);
    });
  });
})();

module.exports = {
  addToWishlist: addToWishlist,
  removeFromWishlist: function () {
    $('body').on('click', '.plpremove-whishlist, .deselect-wishlist', function (e) {
      e.preventDefault();
      e.stopPropagation();
      var deselectNode = $(this);
      var pid = deselectNode.closest('.product').find('.tileproduct-detail').data('pid');
      var url = deselectNode.closest('.product-tile').find('.removeFromWlTileUrl').val();

      $.spinner().start();
      $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        data: {
          pid: pid
        },
        success: function (data) {
          var productObj = data.product;
          if (!productObj) {
            return;
          }

          var productTile = deselectNode.closest('.product-tile');
          if (!productTile || productTile.length === 0) {
            productTile = $('.product-tile').closest('div[data-id="' + productObj.id + '"]');

            if (!productTile.length) {
              productTile = $('.product-tile').closest('div[data-id="' + productObj.masterProductID + '"]');
            }
          }
          $(this).removeClass('deselect-wishlist').addClass('select-wishlist');
          var $imageContainer = productTile.find('.image-container');
          var $currentReference = productTile.find('.plpremove-whishlist');
          var customerDetails = $('.customer-details').data('customer');
          var customerStatus = customerDetails && customerDetails.firstName;

          if (!customerStatus) {
            $('.wishlistTile span').removeAttr('style');
            $('.bagde-img').removeAttr('style');
            $('.customer-content').addClass('d-none');
            $('.tile-image').removeClass('guest-user');
          }
          $imageContainer.find('.wishlistTile').removeClass('deselect-wishlist').addClass('select-wishlist');
          $imageContainer.find('.wishlistTile span').removeClass('svg-svg-96-heart-gray svg-svg-96-heart-gray-dims');
          $currentReference.find('span').removeClass('svg-svg-96-heart-gray svg-svg-96-heart-gray-dims');
          // replacing the content on wishlist page
          var $pgName = $('.pg-name');
          if ($pgName.length > 0 && $pgName.val() === 'wishlist') {
            deselectNode.closest('.wishlist-prod-tile').remove();
            $('.wl-count').text((_, count) => Number(count) - 1);
          }
          $.spinner().stop();
          if ($(productTile).closest('.criteo-sponsored-product').length) {
            data.product.ad_type = 'sponsored product';
            data.product.ad_position = 'in-grid';
          } else if (location.search.indexOf('retail_network') > -1) {
            var url = new URL(document.location);
            var [pageType, adType, adPosition] = url.searchParams.get('retail_network') ? url.searchParams.get('retail_network').split('^') : '';
            data.product.ad_page_type = pageType || '';
            data.product.ad_type = adType || '';
            data.product.ad_position = adPosition || '';
          }
          $('body').trigger('adobeTagManager:removeFromFav', data.product);
          // Showing the wishlist empty message and hiding other wishlist content If wishlist is empty
          if (data.listIsEmpty === true) {
            var $wishlistWrapper = $('.wishlist-wrapper');
            $wishlistWrapper.find('.wishlist-owner.text-center.wishlist-empty-heading').removeClass('d-none');
            $wishlistWrapper.find('.wishlist-header-wrap').addClass('d-none');
            $wishlistWrapper.find('.guest-signin-info.guest-signin-info-lp').addClass('d-none');
          }
        },
        error: function () {
          $.spinner().stop();
        }
      });
    });
  }
};
