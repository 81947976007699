'use strict';

/**
 * appends params to a url
 * @param {Object} $button - button that was clicked to add a product to the wishlist
 */
function activateButton($button) {
  $.spinner().stop();

  setTimeout(function () {
    $button.removeAttr('disabled');
  }, 5000);
}

var elWishlist = $('.wishlist-page').length;
// By default changing the heart icon Selected in wishlist page.
var defaultWishlistSelect = function () {
  if (elWishlist > 0) {
    $('.wishlistItemCards .product-tile').find('.image-container').addClass('wishlist_checked');
    $('.wishlistItemCards .wishlistTile').find('span').addClass('svg-svg-96-heart-gray svg-svg-96-heart-gray-dims');
    $('.wishlistItemCards .wishlistTile').removeClass('select-wishlist').addClass('deselect-wishlist');
  }
};

/**
 * ajax call for wishlist products
 * @param {Object} $this - passing the element for the url reference
 */
var wishlistGridAjax = function () {
  $(document).on('click', '.load-list', function (e) {
    e.preventDefault();
    $.spinner().start();
    var $this = $(this);
    var url = $this.find('a').attr('href');
    $this.attr('disabled', true);
    $.ajax({
      url: url,
      data: {loadAjax: true},
      success: function (response) {
        var selector = $(response).find('div.container.wishlist-page')
        $('div.container.wishlist-page').replaceWith(selector);
        $('body').css('overflow', 'auto');
        $('.container.wishlist-page').addClass('wishlistProdLoaded');
        defaultWishlistSelect();
        window.lazyload.lazyLoadImages();
        $.spinner().stop();
      },
      error: function () {
        $.spinner().stop();
      }
    });
  });
}

module.exports = {
  addToWishlist: function () {
    $('body').on('click', '.wishlistTile.pdpselect-wishlist, .wishlist-pdp-button.pdpselect-wishlist', function (e) {
      e.preventDefault();

      var $this = $(this);
      var url = $this.attr('href');
      var $productDetail = $this.closest('.product-detail');
      var pid = $productDetail.data('pid');
      if (!url || !pid || $this.closest('.complete-the-look-sec').length) {
        return;
      }
      var optionId = $productDetail.find('.product-option').data('option-id') || null;
      var optionVal = $productDetail.find('.options-select option:selected').data('value-id') || null;
      var customerDetails = $('.customer-details').data('customer');
      var customerStatus = customerDetails && customerDetails.firstName;

      $.spinner().start();
      $this.attr('disabled', true);

      $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: {
          pid: pid,
          optionId: optionId,
          optionVal: optionVal
        },
        success: function (data) {
          $('input[name="wishlistUpdated"]').val('true');
          activateButton($this);
          $this.attr('aria-label', 'Remove product from Favorite');
          if (data.product) {
            $('body').trigger('adobeTagManager:addToFav', data.product);
          }
          if ($this.closest('div.applepay-wishlist').length > 0) {
            $this.find('span.button-text').html('WISHLIST');
          } else {
            $this.find('span.button-text').html('ADDED TO WISHLIST');
          }
          $this.addClass('pdp-wishlist-clicked');
          if (!customerStatus) {
            const wishlistPopup = $this.next('.primary-images-container .pdp-customer-content');

            $this.removeClass('pdpselect-wishlist').addClass('pdpdeselect-wishlist');
            wishlistPopup.removeClass('d-none');
            window.setTimeout(() => wishlistPopup.addClass('d-none'), 5000);
          } else {
            $this.removeClass('pdpselect-wishlist').addClass('pdpdeselect-wishlist');
          }
        },
        error: function () {
          activateButton($this);
        }
      });
    });
  },
  removeFromWishlist: function () {
    $('body').on('click', '.pdpdeselect-wishlist', function (e) {
      e.preventDefault();

      var customerDetails = $('.customer-details').data('customer');
      var customerStatus = customerDetails && customerDetails.firstName;
      var $this = $(this);
      var pid = $(this).closest('.product-detail').data('pid');
      var url = $('.primary-images-container').find('.removefromwl').val() + '?pid=' + pid;
      var applePayButtonDiv = $('.pdp-apple-pay-button');

      if (window.SitePreferences &&
        window.SitePreferences.applePayPDPToggle &&
        window.dw &&
        window.dw.applepay &&
        window.ApplePaySession &&
        window.ApplePaySession.canMakePayments() &&
        applePayButtonDiv.length) {
          url += '&isApplePay=true';
      }

      $.spinner().start();
      $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        data: {},
        success: function (data) {
          $('input[name="wishlistUpdated"]').val('true');
          if ($this.find('span.button-text').length > 0) {
            $this.find('span.button-text').html(data.wishListMsg);
          }
          if (!customerStatus) {
            $this
              .addClass('pdpselect-wishlist')
              .removeClass('pdpdeselect-wishlist')
              .attr('aria-label', 'Add product to wishlist')
              .next('.primary-images-container .pdp-customer-content')
              .addClass('d-none');
          } else {
            $this
              .addClass('pdpselect-wishlist')
              .removeClass('pdpdeselect-wishlist')
              .attr('aria-label', 'Add product to wishlist');
          }
          if (data.product) {
            $('body').trigger('adobeTagManager:removeFromFav', data.product);
          }
          $.spinner().stop();
        },
        error: function () {
          $.spinner().stop();
        }
      });
    });
  },
  removeWishlistOnClose: function () {
    $(document).on('click', '.wishlist-container-close', function (e) {
      var $target = $(e.target);
      $target.closest('.customer-content').addClass('d-none');
      $target.closest('.product-tile .hover-content').addClass('d-none');
      $target
        .closest('.image-container')
        .removeClass('wishlist_checked')
        .find('.wishlistTile span')
        .show();
    });
  },
  loadWishlistGridAjax: function () {
    wishlistGridAjax();
    if($('.wishlist-gridview-ajax') && $('.wishlist-gridview-ajax').data('wishlistgridview')) {
      $(document).ready(function () {
        $.spinner().start();
        setTimeout(() => {
          $('.load-list') && $('.load-list').click();
        }, $('.wishlist-gridview-ajax').data('gridviewajaxdelay'));
      });
    }
  }
};
